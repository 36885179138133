import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import "react-multi-carousel/lib/styles.css"; //Carousel css for Errors & Alerts page

import React from "react";
import ReactDOM from "react-dom";

import App from "./App";

// Render app in `#root` element of index.html
ReactDOM.render(<App />, document.getElementById("root"));
