import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState : {
    userList: [],
    userID: 0,
    firstName: "",
    lastName: "",
    groupId: 0,
    secondaryId: "",
    dispenseLimit: 0,
    dailyDispenseLimit: 0,
    dailyPayoutLimit: 0,
    employeeId: "",
    enabled: false,
  },
  reducers: {
    getUserList: (state, {payload}) => {
      state.userList = payload;
    },
    newUser: (state, {payload}) => {
      state.userList.push({
        userID: payload.userID,
        firstName: payload.firstName,
        lastName: payload.lastName,
        groupId: payload.groupId,
        secondaryId: payload.secondaryId,
        dispenseLimit: payload.dispenseLimit,
        dailyDispenseLimit: payload.dailyDispenseLimit,
        dailyPayoutLimit: payload.dailyPayoutLimit,
        employeeId: payload.employeeId,
        enabled: payload.enabled,
      }); 
    },
    updatedUser: (state, {payload}) => {
      const user = state.userList
      .find((user) => user.userID === payload.userID);
        user.firstName = payload.firstName;
        user.lastName = payload.lastName;
        user.groupId = payload.groupId;
        user.secondaryId = payload.secondaryId;
        user.dispenseLimit = payload.dispenseLimit;
        user.dailyDispenseLimit = payload.dailyDispenseLimit;
        user.dailyPayoutLimit = payload.dailyPayoutLimit;
        user.employeeId = payload.employeeId;
    },
    disableUser: (state, {payload}) => {
      const user = state.userList
      .find((user) => user.userID === payload.userID);
      user.enabled = false;
      state.userList = state.userList
      .filter((user) => user.userID !== payload.userID)
    },
    enableUser: (state, {payload}) => {
      const user = state.userList
      .find((user) => user.userID === payload.userID);
      user.enabled = true;
    },
  },
});

export const {getUserList, newUser, updatedUser, disableUser, enableUser} = userSlice.actions;

export default userSlice.reducer;
