/*Package Imports*/
import React from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import { useDispatch } from "react-redux";
/*App Imports*/
import { authActions } from "../../data/redux/store/auth";
import { memberActions } from "../../data/redux/store/member";
import { organizationActions } from "../../data/redux/store/organization";
import { serviceActions } from "../../data/redux/store/service";

const SessionClosed = () => {
  const dispatch = useDispatch();
  dispatch(authActions.PURGE());
  dispatch(authActions.logout());
  dispatch(memberActions.resetMember());
  dispatch(organizationActions.resetOrganization());
  dispatch(serviceActions.resetService());

  return (
    <div className="text-center">
      <h1 className="display-1 font-weight-bold white-font"> Session has been closed.</h1>
      <p className="h2 font-weight-normal mt-3 mb-4 white-font">Your session has been closed because you signed in on another device. If this is a mistake, please contact your admin or MIMO support team.</p>
      <Link to="/">
        <Button color="primary" size="lg">
          Return to Sign In
        </Button>
      </Link>
    </div>
  );
};

export default SessionClosed;
