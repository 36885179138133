/*Package Imports*/
import React, { useState } from "react";
/*App Imports*/
import SignInForm from "./SignInForm";
import ResetPassword from "./ResetPassword";
import Loading from "../Loading/Loading";
import { useSelector } from "react-redux";
import Toastr from "../../../components/custom_components/Toastr";

const SignInPage = () => {
  const isAuth = useSelector((state) => state.auth.isAuthenticated);
  const [resetPassword, setResetPassword] = useState(false);

  /*Need to implement send the reset password link email to member*/
  const changePassword = () => {
    if (resetPassword) {
      Toastr({
        position: "top-center",
        type: "success",
        message: `Please check your email for your new information. This may take a few minutes.`,
      });
    }
    setResetPassword(!resetPassword);
  };

  //if user selects reset password, show the reset password component, else if the member is authorized, start loading data, else show the sign in form
  return <React.Fragment>{resetPassword ? <ResetPassword onClick={changePassword} /> : isAuth ? <Loading /> : <SignInForm onClick={changePassword} />}</React.Fragment>;
};

export default SignInPage;
