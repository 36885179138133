/*Package Imports*/
/*Package Imports*/
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Clock from "react-digital-clock";
import { Collapse, Navbar, Nav, UncontrolledTooltip } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDoorOpen } from "@fortawesome/free-solid-svg-icons";
import { Redirect } from "react-router-dom";
import DarkModeToggle from "react-dark-mode-toggle";
/*App Imports*/
import { authActions } from "../data/redux/store/auth";
import { memberActions } from "../data/redux/store/member";
import { organizationActions } from "../data/redux/store/organization";
import { serviceActions } from "../data/redux/store/service";
import { sidebarActions } from "../data/redux/store/sidebar";
import { locationsActions } from "../data/redux/store/locations";
import { themeActions } from "../data/redux/store/theme";
import { useGetCheckIfMemberSessionOpenQuery, usePutUpdateThemeMutation } from "../data/redux/store/backofficeApi";
import DataPoller from "../components/custom_components/DataPoller";

const NavbarToggle = () => {
  const dispatch = useDispatch();

  const toggleSidebar = (e) => {
    e.preventDefault();
    dispatch(sidebarActions.toggle());
  };

  return (
    <span className="sidebar-toggle d-flex mr-2 mt-3" onClick={toggleSidebar}>
      <i className="hamburger align-self-center" />
    </span>
  );
};

const NavbarDropdowns = () => {
  const dispatch = useDispatch();
  const isOnRight = useSelector((state) => state.sidebar.isOnRight);

  const signOut = () => {
    dispatch(authActions.logout());
    dispatch(authActions.PURGE());
    dispatch(memberActions.PURGE());
    dispatch(organizationActions.PURGE());
    dispatch(serviceActions.PURGE());
    dispatch(locationsActions.PURGE());
  };

  const currentColorMode = useSelector((state) => state.theme.currentColorMode);
  const currentMemberID = useSelector((state) => state.member.memberID);
  const [isDarkMode, setIsDarkMode] = useState(() => currentColorMode.name === "darkMode");

  //endpoint api/member/updatetheme
  const [updateTheme] = usePutUpdateThemeMutation();

  const changeTheme = () => {
    let themeID = 0;
    let themeName = "";

    if (currentColorMode.name === "lightMode") {
      dispatch(themeActions.toggleColorMode("darkMode"));
      dispatch(memberActions.setValue({ field: "defaultTheme", value: "darkMode" }));
      if (localStorage.getItem("rememberMe") === "true") {
        localStorage.setItem("theme", "darkMode");
      }
      themeID = 2;
      themeName = "Dark Mode";
      setIsDarkMode(true);
    } else if (currentColorMode.name === "darkMode") {
      dispatch(themeActions.toggleColorMode("lightMode"));
      dispatch(memberActions.setValue({ field: "defaultTheme", value: "lightMode" }));
      if (localStorage.getItem("rememberMe") === "true") {
        localStorage.setItem("theme", "lightMode");
      }
      themeID = 1;
      themeName = "Light Mode";
      setIsDarkMode(false);
    }

    if (themeID !== 0) {
      const themebody = {
        MemberID: currentMemberID,
        Theme: themeID,
      };

      updateTheme({ body: themebody })
        .unwrap()
        .then((fulfilled) => {})
        .catch((rejected) => {
          console.error(rejected);
        });

      themeID = 0;
    }
  };

  return (
    <Collapse navbar>
      <Nav className={!isOnRight ? "ml-auto" : "mr-auto"} navbar>
        <div className="mt-3 mr-2">
          <Clock />
        </div>
        <div className="mt-3 mr-4">
          <DarkModeToggle
            onChange={() => {
              setIsDarkMode();
              changeTheme();
            }}
            checked={isDarkMode}
            size={50}
          />
        </div>
        <Link to="/">
          <FontAwesomeIcon id={"UncontrolledTooltipleft"} icon={faDoorOpen} className="align-middle mt-3" size="2x" onClick={signOut} />
        </Link>
        <UncontrolledTooltip placement="left" target={"UncontrolledTooltipleft"}>
          Sign Out
        </UncontrolledTooltip>
      </Nav>
    </Collapse>
  );
};

const NavbarComponent = () => {
  const isOnRight = useSelector((state) => state.sidebar.isOnRight);
  const memberSessionID = useSelector((state) => state.auth.memberSessionID);

  const { data: checkSession } = useGetCheckIfMemberSessionOpenQuery(memberSessionID, { skip: memberSessionID === 0, pollingInterval: 60000 });

  return (
    <React.Fragment>
      <Navbar expand className="navbar-theme">
        {checkSession !== undefined && !checkSession && memberSessionID !== 0 ? <Redirect to="/auth/SessionClosed" /> : ""}
        {!isOnRight ? (
          <React.Fragment>
            <NavbarToggle />
            <NavbarDropdowns />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <NavbarDropdowns />
            <NavbarToggle />
          </React.Fragment>
        )}
      </Navbar>
      <DataPoller />
    </React.Fragment>
  );
};

export default NavbarComponent;
