import { createSlice } from "@reduxjs/toolkit";

const initialMemberState = {};

const memberSlice = createSlice({
  name: "member",
  initialState: initialMemberState,
  reducers: {
    setValue(state, action) {
      switch (action.payload.field) {
        case "memberID":
          state.memberID = action.payload.value;
          break;
        case "firstName":
          state.firstName = action.payload.value;
          break;
        case "lastName":
          state.lastName = action.payload.value;
          break;
        case "role":
          state.role = action.payload.value;
          break;
        case "locationNames":
          state.locationNames = action.payload.value;
          break;
        case "department":
          state.department = action.payload.value;
          break;
        case "organizationID":
          state.organizationID = action.payload.value;
          break;
        case "organizationName":
          state.organizationName = action.payload.value;
          break;
        case "email":
          state.email = action.payload.value;
          break;
        case "phone":
          state.phone = action.payload.value;
          break;
        case "locations":
          state.locations = action.payload.value;
          break;
        case "notifications":
          state.notifications = action.payload.value;
          break;
        case "autoReports":
          state.autoReports = action.payload.value;
          break;
        case "defaultTheme":
          state.defaultTheme = action.payload.value;
          break;
        case "isFirstLogin":
          state.isFirstLogin = action.payload.value;
          break;
        case "dismissedList":
          state.dismissedList = action.payload.value;
          break;
        case "permissions":
          state.permissions = action.payload.value;
          break;
        default:
          break;
      }
    },
    setMember(state, action) {
      state.memberID = action.payload.memberID;

      state.firstName = action.payload.firstName;

      state.lastName = action.payload.lastName;

      state.role = action.payload.role;

      state.locationNames = action.payload.locationNames;

      state.department = action.payload.department;

      state.organizationID = action.payload.organizationID;

      state.organizationName = action.payload.organizationName;

      state.email = action.payload.email;

      state.phone = action.payload.phone;

      state.locations = action.payload.locations;

      state.notifications = action.payload.notifications;

      state.autoReports = action.payload.autoReports;

      state.defaultTheme = action.payload.defaultTheme;

      state.isFirstLogin = action.payload.isFirstLogin;
      
      state.dismissedList = action.payload.dismissedList;

      state.permissions = action.payload.permissions;

    },
    resetMember(state) {
      state.memberID = 0;
      state.firstName = "";
      state.lastName = "";
      state.role = "";
      state.locationNames = "";
      state.department = "";
      state.organizationID = "";
      state.organizationName = "";
      state.email = "";
      state.phone = "";
      state.locations = [];
      state.notifications = [];
      state.autoReports = [];
      state.defaultTheme = "";
      state.isFirstLogin = "";
      state.dismissedList = [];
      state.permissions = [];
    
    },
    PURGE(state) {
      return {};
    },
    REHYDRATE(state) {
      return {
        memberID: 0,
        firstName: "",
        lastName: "",
        role: "",
        locationNames: "",
        department: "",
        organizationID: "",
        organizationName: "",
        email: "",
        phone: "",
        locations: [],
        notifications: [],
        autoReports: [],
        defaultTheme: "lightMode",
        isFirstLogin: "",
        dismissedList: [],
        permissions: [],
        
      };
    },
  },
});

export const memberActions = memberSlice.actions;

export default memberSlice.reducer;
