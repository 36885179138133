/*Package Imports*/
import React from "react";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
/*App Imports*/
import MemberData from "./memberData";
import Loader from "../../../components/custom_components/Loader";

//Stepper component to control order data is loaded
const Loading = () => {
  const isAuth = useSelector((state) => state.auth.isAuthenticated);
  const loading = useSelector((state) => state.auth.loadingData);

  //show loading spinner while data is loading and member is authorized; if member is authorized, get member data, else redirect to 404
  return (
    <React.Fragment>
      {loading && isAuth ? <Loader /> : ""}
      {isAuth ? <MemberData /> : <Redirect to="/auth/404" />}
    </React.Fragment>
  );
};

export default Loading;
