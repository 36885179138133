import { createSlice } from "@reduxjs/toolkit";

const departmentSlice = createSlice({
  name: "department",
  initialState : {
    departmentList: [],
    departmentID: 0,
    locationID: 0,
    name: "",
    iconName: "",
    registerBalanced: false,
    enabled: false,
  },
  reducers: {
    getDepartmentList: (state, {payload}) => {
      state.departmentList = payload;
    },
    newDepartment: (state, {payload}) => {
      state.departmentList.push({
        departmentID: payload.departmentID,
        locationID: payload.locationID,
        name: payload.name,
        iconName: payload.iconName,
        registerBalanced: payload.registerBalanced,
        enabled: payload.enabled,
      }); 
    },
    updatedDepartment: (state, {payload}) => {
      const department = state.departmentList
      .find((department) => department.departmentID === payload.departmentID);
        department.name = payload.name;
        department.iconName = payload.iconName;
    },
    disableDepartment: (state, {payload}) => {
      const department = state.departmentList
      .find((department) => department.departmentID === payload.departmentID);
      department.enabled = false;
      state.departmentList = state.departmentList
      .filter((department) => department.departmentID !== payload.departmentID)
    },
    enableDepartment: (state, {payload}) => {
      const department = state.departmentList
      .find((department) => department.departmentID === payload.departmentID);
      department.enabled = true;
    },
  },
});

export const {getDepartmentList, newDepartment, updatedDepartment, disableDepartment, enableDepartment} = departmentSlice.actions;

export default departmentSlice.reducer;
