/*Package Imports*/
import React from "react";
import { Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
/*App Imports*/
import { serviceActions } from "../../../data/redux/store/service";
import { useGetAllMembersQuery, useGetAllLocationsQuery, useGetAllOrganizationsQuery } from "../../../data/redux/store/backofficeApi";
import LocationsData from "./locationsData";

const ServiceData = () => {
  const dispatch = useDispatch(); //service state: sets all members, locations and organizations
  const currentLocations = useSelector((state) => state.locations.locations);
  //endpoint api/member
  const { data: allMembers, error: errorGetAllMembers, isSuccess: gotAllMembers } = useGetAllMembersQuery();
  //endpoint api/locations
  const { data: allLocations, error: errorGetAllLocations, isSuccess: gotAllLocations } = useGetAllLocationsQuery();
  //endpoint api/organization
  const { data: allOrganizations, error: errorGetAllOrganizations, isSuccess: gotAllOrganizations } = useGetAllOrganizationsQuery();

  if (allMembers !== undefined) {
    dispatch(serviceActions.setValue({ field: "allMembersList", value: allMembers }));
  } else if (errorGetAllMembers) {
    console.log(errorGetAllMembers);
  }

  if (allLocations !== undefined) {
    dispatch(serviceActions.setValue({ field: "allLocationsList", value: allLocations }));
  } else if (errorGetAllLocations) {
    console.log(errorGetAllLocations);
  }

  if (allOrganizations !== undefined) {
    dispatch(serviceActions.setValue({ field: "allOrganizationsList", value: allOrganizations }));
  } else if (errorGetAllOrganizations) {
    console.log(errorGetAllOrganizations);
  }

  return (
    <div>
      {errorGetAllMembers !== undefined || errorGetAllLocations !== undefined || errorGetAllOrganizations !== undefined ? (
        <Redirect to="/auth/500" />
      ) : gotAllMembers && gotAllLocations && gotAllOrganizations ? (
        currentLocations.map((location, index) => {
          return <LocationsData key={location.LocationID} locationsList={currentLocations} location={location} />;
        })
      ) : (
        ""
      )}
    </div>
  );
};

export default ServiceData;
