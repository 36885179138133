import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
/*App Imports*/
import { locationsActions } from "../../data/redux/store/locations";
import {
  useGetGrandTotalsReportByDateStringQuery,
  useGetDailyTotalsReportByDateStringQuery,
  useGetAverageNoteCountReportByDateStringQuery,
  useGetVaultTransactionsReportByDateStringQuery,
  useGetMIMOErrorsCountQuery,
  useGetOpenTillCountQuery,
  useGetThresholdCountQuery,
} from "../../data/redux/store/backofficeApi";

const DashboardPoller = (props) => {
  const dispatch = useDispatch();
  const locations = useSelector((state) => state.locations.locations);
  const currentMemberID = useSelector((state) => state.member.memberID);
  const currentMemberRole = useSelector((state) => state.member.role);
  const currentMemberDismissedList = useSelector((state) => state.member.dismissedList);
  const [copyMemberDismissed, setCopyMemberDismissed] = useState(
    currentMemberDismissedList !== undefined
      ? currentMemberDismissedList
          .filter((element) => element.LocationID === props.LocationID)
          .map((element) => element.ErrorLogID)
          .join(",")
      : "0"
  );
  const [grandTotalsDefined, setGrandTotalsDefined] = useState(false);
  const [dailyTotalsDefined, setDailyTotalsDefined] = useState(false);
  const [averageCountDefined, setAverageCountDefined] = useState(false);
  const [vaultTransactionsDefined, setVaultTransactionsDefined] = useState(false);

  let today = new Date();
  let yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  today = today.getMonth() + 1 + "-" + today.getDate() + "-" + today.getFullYear();

  //General Params for end points
  const locationId = props.LocationID;
  const startDate = yesterday.getMonth() + 1 + "-" + yesterday.getDate() + "-" + yesterday.getFullYear();
  const endDate = yesterday.getMonth() + 1 + "-" + yesterday.getDate() + "-" + yesterday.getFullYear();

  const { data: openTillsCount, error: errorGetOpenTillsCount, isSuccess: successOpenTills } = useGetOpenTillCountQuery({ locationId });
  const { data: thresholdAlertsCount, error: errorGetThresholdAlertsCount, isSuccess: successThresholds } = useGetThresholdCountQuery({ locationId, min: 10, max: 90 });
  const { data: mimoErrorsCount, error: errorGetMimoErrorsCount, isSuccess: successMimoErrors } = useGetMIMOErrorsCountQuery({ locationId, memberId: currentMemberID, memberDismissedListString: copyMemberDismissed.length > 0 ? copyMemberDismissed : "0" }, { skip: copyMemberDismissed === "0" });

  //GRAND TOTALS
  const deviceId = null;
  const terminalId = 0;

  //endpoint api/reports/grandtotalsbydatestring?locationid={locationId}&terminalid={terminalId}&startDate={startDate}&endDate={endDate}&deviceid={deviceId}
  const { data: grandTotals, error: errorGetGrandTotals } = useGetGrandTotalsReportByDateStringQuery({ locationId, terminalId, startDate, endDate, deviceId });

  const getGrandTotalsData = useCallback(() => {
    let terminalsData = [];
    let names = [];
    let totals = [];
    if (grandTotals !== undefined && !grandTotalsDefined) {
      const gtData = grandTotals;
      names = [...new Set(gtData.map((data) => data.TerminalName))];
      names.forEach((element) => terminalsData.push({ name: element, total: 0 }));

      terminalsData.forEach((element) => {
        gtData.forEach((dataPoint) => {
          if (dataPoint.TerminalName === element.name) {
            element.total = Number(element.total) + Number(dataPoint.Amount);
          }
        });
      });

      totals = [...terminalsData.map((data) => Number(data.total.toFixed(2)))];

      if (!grandTotalsDefined) {
        dispatch(locationsActions.setValue({ field: "addData", type: "grandTotals", LocationID: props.LocationID, value: { data: totals, labels: names } }));
        setGrandTotalsDefined(true);
      }
      let transformedDate = new Date(yesterday);
      transformedDate = transformedDate.getMonth() + 1 + "-" + transformedDate.getDate() + "-" + transformedDate.getFullYear();
    } else if (errorGetGrandTotals !== undefined) {
      console.log(errorGetGrandTotals);
    }
  }, [dispatch, errorGetGrandTotals, grandTotals, props.LocationID, yesterday, grandTotalsDefined]);

  //DAILY TOTALS

  //endpoint api/reports/dailytotalsbydatestring?locationid={locationId}&startdate={startDate}&enddate={endDate}
  const { data: dailyTotals, error: errorGetDailyTotals } = useGetDailyTotalsReportByDateStringQuery({ locationId, startDate, endDate });

  const getDailyTotalsData = useCallback(() => {
    let transformedData = [];
    let tokens = [];
    let sortedSkeletonDT = [
      { NoteToken: "1¢", DepositCount: 0, DispenseCount: 0, Difference: 0 },
      { NoteToken: "5¢", DepositCount: 0, DispenseCount: 0, Difference: 0 },
      { NoteToken: "10¢", DepositCount: 0, DispenseCount: 0, Difference: 0 },
      { NoteToken: "25¢", DepositCount: 0, DispenseCount: 0, Difference: 0 },
      { NoteToken: "$1", DepositCount: 0, DispenseCount: 0, Difference: 0 },
      { NoteToken: "$5", DepositCount: 0, DispenseCount: 0, Difference: 0 },
      { NoteToken: "$10", DepositCount: 0, DispenseCount: 0, Difference: 0 },
      { NoteToken: "$20", DepositCount: 0, DispenseCount: 0, Difference: 0 },
      { NoteToken: "$100", DepositCount: 0, DispenseCount: 0, Difference: 0 },
    ];
    if (dailyTotals !== undefined && !dailyTotalsDefined) {
      let copyOfData = [...dailyTotals];
      copyOfData = copyOfData.filter((element) => element.TerminalId !== 0);

      tokens = [...new Set(copyOfData.map((data) => data.NoteToken))];

      tokens.forEach((token) => {
        let dispenseCount = 0;
        let depositCount = 0;
        let difference = 0;
        const result = copyOfData.filter(({ NoteToken }) => NoteToken === token);

        result.forEach((result) => {
          dispenseCount += result.DispenseCount;
          depositCount += result.DepositCount;
        });

        difference = depositCount + dispenseCount;

        if (difference !== 0) {
          transformedData.push({ NoteToken: token, DepositCount: depositCount, DispenseCount: dispenseCount, Difference: difference });
        }
      });
      sortedSkeletonDT.forEach((element) =>
        transformedData.forEach((te) => {
          if (element.NoteToken === te.NoteToken) {
            element.DepositCount += te.DepositCount;
            element.DispenseCount += te.DispenseCount;
            element.Difference += te.Difference;
          }
        })
      );
      const tokenList = [...sortedSkeletonDT.map((data) => data.NoteToken)];
      const depositList = [...sortedSkeletonDT.map((data) => data.DepositCount)];
      const dispenseList = [...sortedSkeletonDT.map((data) => Math.abs(data.DispenseCount))];
      const differenceList = [...sortedSkeletonDT.map((data) => Math.abs(data.Difference))];

      if (!dailyTotalsDefined) {
        dispatch(locationsActions.setValue({ field: "addData", type: "dailyTotals", LocationID: props.LocationID, value: { deposit: depositList, dispense: dispenseList, difference: differenceList, labels: tokenList } }));
        setDailyTotalsDefined(true);
      }
    } else if (errorGetDailyTotals !== undefined) {
      console.log(errorGetDailyTotals);
    }
  }, [dailyTotalsDefined, dispatch, props.LocationID, dailyTotals, errorGetDailyTotals]);

  //AVERAGE NOTE COUNT

  //endpoint api/reports/averagenotecountbydatestring?locationid={locationId}&startdate={startDate}&enddate={endDate}
  const { data: averageCount, error: errorGetAverageCount } = useGetAverageNoteCountReportByDateStringQuery({ locationId, startDate, endDate });

  const getAverageNoteCountData = useCallback(() => {
    let sortedSkeletonAC = [
      {
        NoteToken: "1¢",
        DepositCount: 0,
        DispenseCount: 0,
        ChangeCount: 0,
        VaultCount: 0,
      },
      {
        NoteToken: "5¢",
        DepositCount: 0,
        DispenseCount: 0,
        ChangeCount: 0,
        VaultCount: 0,
      },
      {
        NoteToken: "10¢",
        DepositCount: 0,
        DispenseCount: 0,
        ChangeCount: 0,
        VaultCount: 0,
      },
      {
        NoteToken: "25¢",
        DepositCount: 0,
        DispenseCount: 0,
        ChangeCount: 0,
        VaultCount: 0,
      },
      {
        NoteToken: "$1",
        DepositCount: 0,
        DispenseCount: 0,
        ChangeCount: 0,
        VaultCount: 0,
      },
      {
        NoteToken: "$5",
        DepositCount: 0,
        DispenseCount: 0,
        ChangeCount: 0,
        VaultCount: 0,
      },
      {
        NoteToken: "$10",
        DepositCount: 0,
        DispenseCount: 0,
        ChangeCount: 0,
        VaultCount: 0,
      },
      {
        NoteToken: "$20",
        DepositCount: 0,
        DispenseCount: 0,
        ChangeCount: 0,
        VaultCount: 0,
      },
      {
        NoteToken: "$100",
        DepositCount: 0,
        DispenseCount: 0,
        ChangeCount: 0,
        VaultCount: 0,
      },
    ];
    //if successfully retrieved data, create copy of data, get all tokens and terminals in own set of array, then combine the depsoit/dispense/difference based on token with the same terminal id
    if (averageCount !== undefined && !averageCountDefined) {
      let copyOfData = [...averageCount];
      copyOfData = copyOfData.filter((element) => element.TerminalId !== 0);
      //TODO make token entries on one line... if recycler ID equals another entry and they have the same token, combine the dispense and deposit
      let transformedData = [];
      const tokens = [...new Set(copyOfData.map((data) => data.TokenName))];
      const terminals = [...new Set(copyOfData.map((data) => data.TerminalId))];

      terminals.forEach((terminal) => {
        tokens.forEach((token) => {
          let dispenseCount = 0;
          let depositCount = 0;
          let changeCount = 0;
          let vaultCount = 0;

          const result = copyOfData.filter(({ TerminalId, TokenName }) => TerminalId === terminal && TokenName === token);

          result.forEach((result) => {
            dispenseCount += result.DispenseCount;
            depositCount += result.DepositCount;
            changeCount += result.DispenseCount;
            vaultCount += result.DepositCount;
          });
          if (result.length > 0) {
            transformedData.push({
              TerminalId: terminal,
              TerminalName: result[0].TerminalName,
              TokenName: token,
              DepositCount: depositCount,
              DispenseCount: dispenseCount,
              ChangeCount: changeCount,
              VaultCount: vaultCount,
            });
          }
        });
      });

      sortedSkeletonAC.forEach((element) =>
        transformedData.forEach((te) => {
          if (element.NoteToken === te.TokenName) {
            element.DepositCount += te.DepositCount;
            element.DispenseCount += te.DispenseCount;
            element.ChangeCount += te.ChangeCount;
            element.VaultCount += te.VaultCount;
          }
        })
      );
      const tokenListAC = [...sortedSkeletonAC.map((data) => data.NoteToken)];
      const depositListAC = [...sortedSkeletonAC.map((data) => data.DepositCount)];
      const dispenseListAC = [...sortedSkeletonAC.map((data) => Math.abs(data.DispenseCount))];
      const changeListAC = [...sortedSkeletonAC.map((data) => Math.abs(data.ChangeCount))];
      const vaultListAC = [...sortedSkeletonAC.map((data) => Math.abs(data.VaultCount))];

      if (!averageCountDefined && locations.find((element) => element.LocationID === props.LocationID).DBAverageCount.length <= 0) {
        dispatch(locationsActions.setValue({ field: "addData", type: "averageNote", LocationID: props.LocationID, value: { labels: tokenListAC, deposit: depositListAC, dispense: dispenseListAC, change: changeListAC, vault: vaultListAC } }));
        setAverageCountDefined(true);
      }
    } else if (errorGetAverageCount !== undefined) {
      console.log(errorGetAverageCount);
    }
  }, [averageCount, errorGetAverageCount, averageCountDefined, dispatch, props.LocationID, locations]);

  //VAULT TRANSACTIONS
  //endpoint api/reports/vaulttransactionsbydatestring?locationid={locationId}&startdate={startDate}&enddate={endDate}
  const { data: vaultTransactions, error: errorGetVaultTransactions } = useGetVaultTransactionsReportByDateStringQuery({ locationId, startDate, endDate });

  const getVaultTransactionData = useCallback(() => {
    if (vaultTransactions !== undefined && !vaultTransactionsDefined) {
      let copyOfData = [...vaultTransactions];
      const terminals = [...new Set(copyOfData.map((data) => data.TerminalId))];
      const series = [];

      terminals.forEach((terminal) => {
        const result = copyOfData.filter(({ TerminalId }) => TerminalId === terminal);
        let name = result[0].TerminalName;
        const totalAmount = result.map((data) => data.AfterTotal + data.Amount);
        const datesCreated = result.map((data) => data.DateCreated.split("T")[1]);

        series.push({
          name: name,
          data: totalAmount,
          labels: datesCreated,
        });
      });

      if (!vaultTransactionsDefined) {
        dispatch(locationsActions.setValue({ field: "addData", type: "vaultTransactions", LocationID: props.LocationID, value: { data: series } }));
        setVaultTransactionsDefined(true);
      }
    } else if (errorGetVaultTransactions !== undefined) {
      console.log(errorGetVaultTransactions);
    }
  }, [dispatch, props.LocationID, vaultTransactionsDefined, errorGetVaultTransactions, vaultTransactions]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getGrandTotalsData();
      getDailyTotalsData();
      getAverageNoteCountData();
      getVaultTransactionData();
      if (locations.find((element) => element.LocationID === locationId).OpenTillAlertsCount === -1 && successOpenTills) {
        dispatch(locationsActions.setValue({ field: "addData", type: "openTillCount", LocationID: locationId, value: openTillsCount }));
      }
      if (locations.find((element) => element.LocationID === locationId).ThresholdAlertsCount === -1 && successThresholds) {
        dispatch(locationsActions.setValue({ field: "addData", type: "thresholdCount", LocationID: locationId, value: thresholdAlertsCount }));
      }
      if (locations.find((element) => element.LocationID === locationId).MIMOErrorsCount === -1 && successMimoErrors) {
        dispatch(locationsActions.setValue({ field: "addData", type: "mimoErrorsCount", LocationID: locationId, value: mimoErrorsCount }));
      }
      if (grandTotalsDefined && dailyTotalsDefined && averageCountDefined && vaultTransactionsDefined && successOpenTills && successMimoErrors && successThresholds) {
        props.setNewLocationID();
      }
    }
    return () => (mounted = false);
  }, [
    getGrandTotalsData,
    getDailyTotalsData,
    getAverageNoteCountData,
    getVaultTransactionData,
    averageCountDefined,
    dailyTotalsDefined,
    dispatch,
    grandTotalsDefined,
    locationId,
    locations,
    mimoErrorsCount,
    openTillsCount,
    props,
    successMimoErrors,
    successOpenTills,
    successThresholds,
    thresholdAlertsCount,
    vaultTransactionsDefined,
  ]);

  return <React.Fragment></React.Fragment>;
};

export default DashboardPoller;
