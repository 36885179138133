import { createSlice } from "@reduxjs/toolkit";

const themes = {
  modern: {
    primary: "#3B7DDD",
    secondary: "#60002D",
    tertiary: "#669ae5",
    success: "#28a745",
    info: "#20c997",
    warning: "#fd7e14",
    danger: "#dc3545",
    dark: "#153d77",
    card: "#FFFFFF",
    font: "#FFFFFF",
  },
  classic: {
    primary: "#38C485",
    secondary: "#60002D",
    tertiary: "#41D492",
    success: "#a877b5",
    info: "#00bcd4",
    warning: "#F2B51D",
    danger: "#F26666",
    dark: "#203A45",
    card: "#FFFFFF",
    font: "#FFFFFF",
  },
  dark: {
    primary: "#687ae8",
    secondary: "#60002D",
    tertiary: "#95aac9",
    success: "#0c9",
    info: "#19b5fe",
    warning: "#f7bc06",
    danger: "#f2545b",
    dark: "#28304e",
    card: "#FFFFFF",
    font: "#FFFFFF",
  },
  light: {
    primary: "#3B7DDD",
    secondary: "#60002D",
    tertiary: "#669ae5",
    success: "#28a745",
    info: "#20c997",
    warning: "#fd7e14",
    danger: "#dc3545",
    dark: "#153d77",
    card: "#FFFFFF",
    font: "#FFFFFF",
  },
};

const colorModes = {
  lightMode: {
    name: "lightMode",
    backgroundColor: "#e8e2e7",
    subBackgroundColor: "#ffffff",
    color: "#203A45",
  },
  darkMode: {
    name: "darkMode",
    backgroundColor: "#2f0026",
    subBackgroundColor: "#5c2853",
    color: "#ffffff",
  },
};

const initialThemeState = {
  currentTheme: themes.modern,
  themes: themes,
  currentColorMode: colorModes.lightMode,
  colorModes: colorModes,
};

const themeSlice = createSlice({
  name: "theme",
  initialState: initialThemeState,
  reducers: {
    toggle(state, actions) {
      state.currentTheme = state.themes[actions.payload];
    },
    toggleColorMode(state, actions) {
      state.currentColorMode = state.colorModes[actions.payload];
    },
  },
});

export const themeActions = themeSlice.actions;

export default themeSlice.reducer;
