/*Package Imports*/
import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import ReduxToastr from "react-redux-toastr";
import store from "./data/redux/store/store";
import { persistor } from "./data/redux/store/store";
/*App Imports*/
import BackofficeContext from "./data/context/backoffice-context";
import Routes from "./routes/Routes";

function App() {
  useEffect(() => {
    // Remove `active` className from `.splash` element in `public/index.html`
    !document.querySelector(".splash") || document.querySelector(".splash").classList.remove("active");
  }, []);

  return (
    <BackofficeContext.Provider
      value={{
        /*Used for dashboard reports data*/
        dashboard: {
          mostRecentlyClosedBusinessDate: { date: "", string: "" }, //date: the one to pass to api, string: the visual format of date for rendering
          grandTotalsData: [], //y axis of chart
          grandTotalsDataLabels: [], //x axis of chart
          dailyTotalsDataDeposit: [],
          dailyTotalsDataDispense: [],
          dailyTotalsDataDifference: [],
          dailyTotalsDataLabels: [],
          averageCountDataDeposit: [],
          averageCountDataDispense: [],
          averageCountDataChange: [],
          averageCountDataVault: [],
          averageCountDataLabels: [],
          vaultTransactionsData: [],
          vaultTransactionsCategories: [],
        },

        /*Data handling for Reports Page*/
        reports: {
          selectedLocation: "",
          selectedReport: "",
          selectedReportDescription: "",
          dateRange: {
            startDate: "",
            endDate: "",
          },
          reportList: [],
          showReportView: false,
        },

        /*Data Handling for Settings Page*/
        settings: {
          activeTab: "accountInfo",
          theme: "default",
          notifcations: [
            {
              id: "mimoErrors",
              NotificationTypeID: 1,
              NotificationMethodID: 0,
              selectedDays: [],
              startTime: "",
              endTime: "",
              emailNotifcations: false,
              smsNotifcations: false,
              timeDelay: "",
            },
            {
              id: "exposureAlerts",
              NotificationTypeID: 3,
              NotificationMethodID: 0,
              selectedDays: [],
              startTime: "",
              endTime: "",
              emailNotifcations: false,
              smsNotifcations: false,
              percentage: "",
            },
            {
              id: "thresholdAlerts",
              NotificationTypeID: 2,
              NotificationMethodID: 0,
              selectedDays: [],
              startTime: "",
              endTime: "",
              emailNotifcations: false,
              smsNotifcations: false,
              minThreshold: "",
              maxThreshold: "",
            },
            {
              id: "openTillAlerts",
              NotificationTypeID: 4,
              NotificationMethodID: 0,
              selectedDays: [],
              startTime: "",
              endTime: "",
              emailNotifcations: false,
              smsNotifcations: false,
              timeDelay: "",
            },
          ],
        },
      }}
    >
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Routes />
          <ReduxToastr timeOut={5000} newestOnTop={true} position="top-right" transitionIn="fadeIn" transitionOut="fadeOut" progressBar closeOnToastrClick />
        </PersistGate>
      </Provider>
    </BackofficeContext.Provider>
  );
}

export default App;
