import { createSlice } from "@reduxjs/toolkit";

const initialServiceState = {};

const serviceSlice = createSlice({
  name: "service",
  initialState: initialServiceState,
  reducers: {
    setValue(state, action) {
      switch (action.payload.field) {
        case "allOrganizationsList":
          state.allOrganizationsList = action.payload.value;
          break;
        case "allLocationsList":
          state.allLocationsList = action.payload.value;
          break;
        case "allMembersList":
          state.allMembersList = action.payload.value;
          break;
        case "changedOrganizationData":
          state.changedOrganizationData = action.payload.value;
          break;
        case "allErrors":
          let tempArray = [...state.allErrors];
          const payload = action.payload.value;
          payload.forEach((element) => {
            if (tempArray.findIndex((e) => e.ErrorLogID === element.ErrorLogID) === -1) {
              tempArray.push(...payload);
            }
          });

          state.allErrors = tempArray;
          break;
        default:
          break;
      }
    },
    resetService(state) {
      state.allOrganizationsList = [];
      state.allLocationsList = [];
      state.allMembersList = [];
      state.changedOrganizationData = false;
      state.allErrors = [];
    },
    PURGE(state) {
      return {};
    },
    REHYDRATE(state) {
      return {
        allOrganizationsList: [],
        allLocationsList: [],
        allMembersList: [],
        changedOrganizationData: false,
        allErrors: [],
      };
    },
  },
});

export const serviceActions = serviceSlice.actions;

export default serviceSlice.reducer;
