/*Package Imports*/
import { toastr } from "react-redux-toastr";

const Toastr = (props) => {
  const options = {
    timeOut: parseInt("4000"),
    showCloseButton: true,
    progressBar: false,
    position: props.position,
  };
  let toastrInstance;
  if (props.type === "success") {
    toastrInstance = toastr.success;
  } else if (props.type === "error") {
    toastrInstance = toastr.error;
  } else if (props.type === "warning") {
    toastrInstance = toastr.warning;
  }
  toastrInstance(props.message, "", options);
};

export default Toastr;
