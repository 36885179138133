/*Package Imports*/
import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { reducer as toastrReducer } from "react-redux-toastr";
import { combineReducers } from "redux";
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";
import storage from "redux-persist/lib/storage/session";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
/*App Imports*/
import authReducer from "./auth";
import sidebarReducer from "./sidebar";
import themeReducer from "./theme";
import memberReducer from "./member";
import organizationReducer from "./organization";
import serviceReducer from "./service";
import locationsReducer from "./locations";
import { backofficeApi } from "./backofficeApi";
/*Slices for BackOffice+*/
import locationReducer from "./location";
import departmentReducer from "./department";
import registerReducer from "./register";
import userReducer from "./user";
import transactionReducer from "./transaction";

import service from "./service";

const persistConfig = {
  key: "root",
  stateReconciler: autoMergeLevel2,
  storage,
  version: 1,
  blacklist: [backofficeApi.reducerPath, "service.allErrors"],
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    auth: authReducer, 
    sidebar: sidebarReducer,
    theme: themeReducer,
    member: memberReducer,
    location: locationReducer,
    department: departmentReducer,
    register: registerReducer,
    user: userReducer,
    transaction: transactionReducer,
    organization: organizationReducer,
    service: serviceReducer,
    toastr: toastrReducer,
    locations: locationsReducer,
    [backofficeApi.reducerPath]: backofficeApi.reducer,
  })
);

const store = configureStore({
  reducer: persistedReducer,
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // AH - i do no understand this list
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER
        ],
      },
    }).concat(backofficeApi.middleware),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

export default store;
export const persistor = persistStore(store);
